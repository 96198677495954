import React, { useEffect, useState } from "react";
import Nav from "../NavBar/Nav";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./MobileAppDevelopment.css";
import { Link } from "react-router-dom";
import { MdOutlineArrowForward } from "react-icons/md";
import { IoIosArrowRoundForward } from "react-icons/io";
import { GiCheckMark } from "react-icons/gi";

import compimg1 from "../../Web Assets/website images (13) 1.svg";
import compimg2 from "../../Web Assets/,,,ghgh 1.svg";
import compimg3 from "../../Web Assets/website images (20) 1.svg";
import compimg4 from "../../Web Assets/website images (14) 1.svg";

import icon1 from "../../Web Assets/icons/html5-logo 1.svg";
import icon2 from "../../Web Assets/icons/JavaScript-Emblem 1.svg";
import icon3 from "../../Web Assets/icons/tailwindcss-logo 1.svg";
import icon4 from "../../Web Assets/icons/Node.js-Logo.wine 1.svg";
import icon5 from "../../Web Assets/icons/python-logo-png-big-image-png-2400 1.svg";
import icon6 from "../../Web Assets/icons/php_PNG43 1.svg";
import icon7 from "../../Web Assets/icons/image 30.svg";
import icon8 from "../../Web Assets/icons/mongodb-250x250 1.svg";
import icon9 from "../../Web Assets/icons/mysql_PNG23 1.svg";

import iosFintech from "../../App Assets/e-money 1.svg";
import iosREALeSTATE from "../../App Assets/home 1.svg";
import iosEcommerce from "../../App Assets/shopping-cart (1) 1.svg";
import iosHealthCare from "../../App Assets/first-aid-kit 1.svg";
import iosSupplyChain from "../../App Assets/stock 1.svg";
import iosLogistics from "../../App Assets/food-truck 1.svg";
import iosBankling from "../../App Assets/bank-transfer 1.svg";

import Clutch from "../../assets/mobile-dev-securities-and-exchange-commission-of-pakistan-logo-7828A88801-seeklogo 2.png";
import ISO from "../../assets/mobile-dev-iso-2-1-logo-png-transparent 2.png";
import Pasha from "../../assets/P@SHA_Logo 2.png";
import PSeb from "../../assets/pseb-logo-1 2.png";
import { GoPlus } from "react-icons/go";
import { LuMinus } from "react-icons/lu";

import contactUs from "../../assets/contactus-mobile-dev.png";
import appDevSericesPic from "../../assets/app-dev-services.png";
import pseudo from "../../assets/pseudo.png";
import TestimonialImg from "../../assets/Ellipse 8.png";

import img7 from "../../Web Assets/webimageedge4.svg";
import img8 from "../../Web Assets/webimageedge3.svg";
import img9 from "../../Web Assets/webimageedge2.svg";
import img10 from "../../Web Assets/webimageedge1.svg";
import img11 from "../../Web Assets/Speaksimage 33.svg";
import img12 from "../../Web Assets/SpeaksGroup 72.svg";
import img13 from "../../Web Assets/Speaksimage 35.svg";
import img14 from "../../Web Assets/Speaksimage 32.svg";

import Footer from "../Footer/Footer";
import Loader from "../Loader/Loader";
import ContactUs from "../ContactUs/ContactUs";

import "./WebDevelopment.css";

const FAQDATA = [
  {
    id: 1,
    heading: "How could a stunning website transform your business?",
    data: "Imagine a beautifully designed website that attracts customers, showcases your brand's personality, and drives sales. It’s not just a website; it’s the heart of your business.",
  },
  {
    id: 2,
    heading: "What if your website could truly connect with your audience?",
    data: "Envision your visitors feeling understood and valued the moment they land on your site, creating a bond that keeps them coming back. That connection can turn browsers into loyal customers.",
  },
  {
    id: 3,
    heading: "How would a seamless online experience elevate your brand?",
    data: "Picture your customers navigating effortlessly through your site, leaving them impressed and satisfied. A smooth experience reflects professionalism and care, enhancing their perception of your brand.",
  },
  {
    id: 4,
    heading: "What dreams could a powerful website help you achieve?",
    data: "Think about your vision: reaching more customers, sharing your story, or expanding your impact. A well-crafted website can be the catalyst that turns those dreams into reality, making your aspirations tangible.",
  },
];

const WebDevelopment = () => {
  const [openId, setOpenId] = useState(null);
  const [activeMainTab, setActiveMainTab] = useState("Web Development");
  const [loading, setLoading] = useState(true);

  const [platForm, setPlatForm] = useState();
  const [selectDevice, setSelectDevice] = useState();
  const [stage, setStage] = useState();
  const [totalPlatoformPrice, setTotalPlatoformPrice] = useState();
  const [totalSelectDevicePrice, setTotalSelectDevicePrice] = useState();
  const [totalStagePrice, setTotalStagePrice] = useState();
  const [totalPrice, setTotalPrice] = useState(null);

  const [isScrolled, setIsScrolled] = useState(false);

  const toggleFaq = (id) => {
    setOpenId(openId === id ? null : id);
  };

  var settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    autoplaySpeed: 1000,
    slidesToScroll: 1,
    draggable: true,
  };

  const handleMainTabClick = (tab) => {
    setActiveMainTab(tab);
  };

  useEffect(() => {
    const imagesToLoad = [
      iosFintech,
      iosREALeSTATE,
      iosEcommerce,
      iosHealthCare,
      iosSupplyChain,
      iosLogistics,
      iosBankling,
      Clutch,
      ISO,
      Pasha,
      PSeb,
      contactUs,
      appDevSericesPic,
      pseudo,
      TestimonialImg,
    ];

    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = src;
      });
    };

    const loadImages = async () => {
      try {
        const promises = imagesToLoad.map((image) => loadImage(image));
        await Promise.all(promises);
        setLoading(false);
      } catch (error) {
        console.error("Error loading images:", error);
      }
    };

    loadImages();
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 230) {
      console.log("window.scrollY", window.scrollY);
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    AOS.init({ duration: 2000 });
    setLoading(false);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="Webappdev-container">
            <div
              className={`Mobileappdev-nav  ${
                isScrolled ? "Mobileappdev-nav-scrolled" : ""
              } `}
            >
              <Nav />
            </div>
            <div className="mobile-app-dev-wrapper">
              <div className="Bread-crum-mobileapp-dev">
                <Link to="/">Home</Link>
                <IoIosArrowRoundForward />
                <p>Services</p>
                <IoIosArrowRoundForward />
                <Link className="active-bread-crum-current-page">
                  Web Development
                </Link>
              </div>

              <div className="mobile-app-hero-headings">
                <p>
                  Web
                  <br />
                  Development
                </p>
                <span>
                  Build Dynamic, Scalable, and Secure Websites with Us
                </span>
                <Link to={"/contact"}>
                  Contact Us Today
                  <MdOutlineArrowForward size={22} />
                </Link>
              </div>

              <div className="mobile-app-dev-hero-footer">
                <div>
                  <p>362+</p>
                  <span>Projects</span>
                </div>
                <div>
                  <p>257+</p>
                  <span>Mobile Tech Experts</span>
                </div>
                <div>
                  <p>4.8/5</p>
                  <span>On Clutch</span>
                </div>
              </div>
            </div>
          </div>

          {/* services section */}
          <div className="mobileappedev-think-legion">
            <p>
              From Concept to Deployment – Legions Soft Delivers Full-Stack Web
              Solutions
            </p>
            <span>
              At Legions Soft, we build websites with a focus on speed,
              scalability, and seamless user experience. Our web development
              services cover everything from custom web applications, e-commerce
              solutions, and content management systems (CMS) to responsive
              designs and API integrations. We use cutting-edge technology to
              create websites that enhance business growth and drive user
              engagement.
            </span>

            <div className="mobileappedev-think-legion-section-2">
              <p>Web Development with Cutting-Edge Technologies</p>
              <span>
                At Legions Soft, we develop websites that are SEO-friendly,
                secure, and optimized for high performance. Our web solutions
                are built to meet the challenges of modern businesses by
                incorporating the latest web standards and technologies.
              </span>
              <div className="mobileappedev-think-legion-section-2-part-1">
                <div>
                  <p>Front-End Development</p>
                  <span>
                    Responsive Designs: We design visually compelling user
                    interfaces that adjust seamlessly across desktop, tablet,
                    and mobile devices.
                  </span>
                  <br />
                  <br />
                  <span>
                    Scalable Architectures: Our back-end architectures support
                    thousands of concurrent users, using database optimizations,
                    load balancers, and caching layers like Redis and Memcached.
                  </span>
                  <div>
                    <p>
                      <GiCheckMark />
                      Business Goals
                    </p>
                    <p>
                      <GiCheckMark />
                      User Requirements
                    </p>
                    <p>
                      <GiCheckMark />
                      Market Research
                    </p>
                  </div>
                </div>
                <img src={img7} alt="img" />
              </div>

              <div className="mobileappedev-think-legion-section-2-part-2">
                <img src={img8} alt="img" />
                <div>
                  <p>Back-End Development</p>
                  <span>
                    High-Performance Back-End Solutions: We build secure,
                    high-speed back-end infrastructures using technologies like
                    Node.js, Python (Django, Flask), PHP (Laravel, Symfony), and
                    Ruby on Rails.
                  </span>
                  <br />
                  <br />
                  <span>
                    Additionally, we can adapt and re-engineer your business
                    tools for mobile access, enabling your employees to retrieve
                    and submit information in real-time, anytime and anywhere.
                  </span>
                  <div>
                    <p>
                      <GiCheckMark />
                      Information Architecture
                    </p>
                    <p>
                      <GiCheckMark />
                      Wireframes
                    </p>
                  </div>
                </div>
              </div>

              <div className="mobileappedev-think-legion-section-2-part-1">
                <div>
                  <p>Custom Web Development Solutions</p>
                  <span>
                    Tailored Business Solutions: Whether you need a custom
                    e-commerce platform, an enterprise-level CMS, or a complex
                    web application, we deliver fully customizable solutions
                    that scale with your business.
                  </span>
                  <br />
                  <br />
                  <span>
                    Secure Transactions: Our e-commerce and business platforms
                    are PCI DSS compliant and come with built-in SSL encryption,
                    two-factor authentication (2FA), and advanced security
                    protocols to protect your data.
                  </span>
                  <div>
                    <p>
                      <GiCheckMark />
                      Visual Design
                    </p>
                    <p>
                      <GiCheckMark />
                      Style Guide & Design Systems
                    </p>
                    <p>
                      <GiCheckMark />
                      Prototype and Testing
                    </p>
                  </div>
                </div>
                <img src={img9} alt="img" />
              </div>

              <div className="mobileappedev-think-legion-section-2-part-2">
                <img src={img10} alt="img" />
                <div>
                  <p>Cross-Browser Compatibility</p>
                  <span>
                    We ensure your website works seamlessly across all major
                    browsers like Google Chrome, Mozilla Firefox, Safari, and
                    Microsoft Edge, while being compatible with older browser
                    versions
                  </span>
                  <div>
                    <p>
                      <GiCheckMark />
                      Information Architecture
                    </p>
                    <p>
                      <GiCheckMark />
                      Wireframes
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  */}
          <div className="webappedev_comprehensive">
            <p>Comprehensive Web Development Services</p>
            <span>
              Our full-stack web development services cover every aspect of web
              development, ensuring that your website is not only aesthetically
              pleasing but also highly functional and secure
            </span>
            <div className="webappedev_comprehensive-section-1">
              <div className="mobileappedev-think-legion-section-2-part-1">
                <div>
                  <p>Front-End Development</p>
                  <span>
                    We build responsive, mobile-first websites using modern
                    front-end technologies that ensure your site loads quickly
                    and provides an excellent user experience.
                  </span>
                  <br />
                  <br />
                  <span>
                    Pixel-perfect, cross-browser compliant coding using SASS and
                    LESS for modular and maintainable stylesheets.
                  </span>
                  <br />
                  <br />
                  <span>
                    Using React, Vue, and Angular, we create interactive user
                    interfaces with seamless navigation, real-time updates, and
                    SPA (Single Page Application) architecture for smooth user
                    experiences.
                  </span>
                  <div>
                    <p>
                      <GiCheckMark />
                      HTML5/CSS3 Development
                    </p>
                    <p>
                      <GiCheckMark />
                      JavaScript Development
                    </p>
                  </div>
                </div>
                <img src={compimg1} alt="img" />
              </div>

              <div className="mobileappedev-think-legion-section-2-part-2">
                <img src={compimg2} alt="img" height={700} />
                <div>
                  <p>Back-End Development</p>
                  <span>
                    Our back-end developers build robust, secure, and scalable
                    systems using the latest technologies and frameworks
                  </span>
                  <br />
                  <br />
                  <span>
                    High-performance back-end systems capable of handling
                    real-time data and API-heavy platforms,
                  </span>
                  <br />
                  <br />
                  <span>
                    We design efficient and scalable database schemas using
                    PostgreSQL, MySQL, and MongoDB, optimizing for high
                    performance under heavy workloads.
                  </span>
                  <br />
                  <br />
                  <span>
                    Secure back-end services for data-driven websites and web
                    applications with a focus on scalability and rapid
                    development
                  </span>
                  <div>
                    <p>
                      <GiCheckMark />
                      Node.js, Express.js
                    </p>
                    <p>
                      <GiCheckMark />
                      Database Management
                    </p>
                    <p>
                      <GiCheckMark />
                      Python (Django, Flask)
                    </p>
                  </div>
                </div>
              </div>

              <div className="mobileappedev-think-legion-section-2-part-1">
                <div>
                  <p>Custom API Development & Integration</p>
                  <span>
                    We develop custom APIs to connect your web application with
                    external services and third-party platforms, ensuring
                    seamless integrations for payment gateways, CRM systems, or
                    marketing tools.
                  </span>
                  <br />
                  <br />
                  <span>
                    Build scalable and flexible REST APIs for modular web
                    applications.
                  </span>
                  <br />
                  <br />
                  <span>
                    When performance and complex queries matter, we use GraphQL
                    for more efficient data retrieval and fewer API calls.
                  </span>
                  <div>
                    <p>
                      <GiCheckMark />
                      RESTful APIs
                    </p>
                    <p>
                      <GiCheckMark />
                      GraphQL APIs
                    </p>
                  </div>
                </div>
                <img src={compimg3} alt="img" height={600} />
              </div>

              <div className="mobileappedev-think-legion-section-2-part-2">
                <img src={compimg4} alt="img" />
                <div>
                  <p>WordPress & CMS Development</p>
                  <span>
                    We build and customize WordPress websites and develop custom
                    themes and plugins to deliver a unique user experience
                    tailored to your business.
                  </span>
                  <br />
                  <br />
                  <span>
                    Develop highly responsive, lightweight themes that load
                    faster and perform well across all devices.
                  </span>
                  <br />
                  <br />
                  <span>
                    Extend the functionality of your WordPress site with custom
                    plugin development that integrates seamlessly with your
                    platform.
                  </span>
                  <div>
                    <p>
                      <GiCheckMark />
                      Custom Themes
                    </p>
                    <p>
                      <GiCheckMark />
                      Python (Django, Flask)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ios dev solution section */}
          <div className="Mobileappdev-ios-dev-section">
            <p>
              Our Web Development Solutions
              <br />
              For Different
              <span> Industries</span>
            </p>
            <div className="Mobileappdev-ios-dev-section-items">
              <div>
                <img src={iosFintech} alt="abc" />
                <p>Fintech</p>
              </div>
              <div>
                <img src={iosREALeSTATE} alt="abc" />
                <p>Real Estate</p>
              </div>
              <div>
                <img src={iosEcommerce} alt="abc" />
                <p>E-Commerce</p>
              </div>
              <div>
                <img src={iosHealthCare} alt="abc" />
                <p>Healthcare</p>
              </div>
              <div>
                <img src={iosSupplyChain} alt="abc" />
                <p>Supply Chain</p>
              </div>
              <div>
                <img src={iosLogistics} alt="abc" />
                <p>Logistics</p>
              </div>
              <div>
                <img src={iosBankling} alt="abc" />
                <p>Banking</p>
              </div>
            </div>
          </div>
          {/* tech section */}
          <div className="Mobileappdev-tech-section">
            <p>Technologies & Tools We Use</p>
            <div className="Mobileappdev-tech-section-container">
              <div className="Mobileappdev-tech-tabs">
                <button
                  className={
                    activeMainTab === "Web Development"
                      ? "Mobileappdev-tech-tabs-button-active"
                      : ""
                  }
                  onClick={() => handleMainTabClick("Web Development")}
                >
                  Front-End Development
                </button>
                <button
                  className={
                    activeMainTab === "Back-end Development"
                      ? "Mobileappdev-tech-tabs-button-active"
                      : ""
                  }
                  onClick={() => handleMainTabClick("Back-end Development")}
                >
                  Back-end Development
                </button>
                <button
                  className={
                    activeMainTab === "Databases"
                      ? "Mobileappdev-tech-tabs-button-active"
                      : ""
                  }
                  onClick={() => handleMainTabClick("Databases")}
                >
                  Databases
                </button>
              </div>
              {activeMainTab === "Web Development" && (
                <div className="Mobileappdev-tech-section-tabs-body">
                  <div>
                    <span>
                      <img src={icon1} alt="abc" />
                    </span>
                    <p>HTML5</p>
                  </div>
                  <div>
                    <span>
                      <img src={icon2} alt="abc" />
                    </span>
                    <p>Javascript</p>
                  </div>
                  <div>
                    <span>
                      <img src={icon3} alt="abc" />
                    </span>
                    <p>Tailwind</p>
                  </div>
                </div>
              )}
              {activeMainTab === "Back-end Development" && (
                <div className="Mobileappdev-tech-section-tabs-body">
                  <div>
                    <span>
                      <img src={icon4} alt="abc" />
                    </span>
                    <p>Node.js</p>
                  </div>
                  <div>
                    <span>
                      <img src={icon5} alt="abc" />
                    </span>
                    <p>Python</p>
                  </div>
                  <div>
                    <span>
                      <img src={icon6} alt="abc" />
                    </span>
                    <p>PHP</p>
                  </div>
                </div>
              )}
              {activeMainTab === "Databases" && (
                <div className="Mobileappdev-tech-section-tabs-body">
                  <div>
                    <span>
                      <img src={icon7} alt="abc" />
                    </span>
                    <p>PostgreSQL</p>
                  </div>
                  <div>
                    <span>
                      <img src={icon8} alt="abc" />
                    </span>
                    <p>MongoDB</p>
                  </div>
                  <div>
                    <span>
                      <img src={icon9} alt="abc" />
                    </span>
                    <p>MySQL</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* What It Looks Like */}
          <div className="Mobileappdev-looks-like">
            <p>What It Looks Like</p>
            <div className="Mobileappdev-looks-like-section-1">
              <div>
                <p>Market analysis to understand your goals and users</p>
              </div>
              <div>
                <p>Collaboration and communication to stay in sync</p>
              </div>
              <div>
                <p>Rapid prototyping to test and validate concepts</p>
              </div>
              <div>
                <p>Iterative development to get you to market fast</p>
              </div>
            </div>
            <p>Our Process</p>
            <div className="Mobileappdev-looks-like-section-2">
              <Slider {...settings}>
                <div className="Mobileappdev-looks-like-section-2-part-1">
                  <p>1</p>
                  <p>Analysis</p>
                  <span>
                    To explore your vision, customers, and the problem to be
                    solved.
                  </span>
                </div>
                <div className="Mobileappdev-looks-like-section-2-part-1">
                  <p>2</p>
                  <p>Design</p>
                  <span>
                    To craft the look, feel, and flow of your product.
                  </span>
                </div>
                <div className="Mobileappdev-looks-like-section-2-part-1">
                  <p>3</p>
                  <p>Development</p>
                  <span>
                    To iteratively build the perfect product to meet your goals.
                  </span>
                </div>
                <div className="Mobileappdev-looks-like-section-2-part-1">
                  <p>4</p>
                  <p>Release</p>
                  <span>
                    To get your finished product out to your customers.
                  </span>
                </div>
                <div className="Mobileappdev-looks-like-section-2-part-1">
                  <p>5</p>
                  <p>Support</p>
                  <span>To maintain and improve your product over time.</span>
                </div>
              </Slider>
            </div>
            <Link>Contact Us</Link>
          </div>

          <div className="Mobileappdev-portfolio">
            <p>Our Work Speaks for Itself...</p>
            <span>Some of our successful web development projects:</span>
            <div>
              <Link>
                <img src={img14} alt="img" />
                <span>Honeywell</span>
                <p>
                  Honeywell is a multinational conglomerate that provides
                  various products and services, including manufacturing
                  solutions for multiple industries.
                </p>
              </Link>
              <Link>
                <img src={img12} alt="img" />
                <span>Newegg</span>
                <p>
                  Newegg is a major online retailer specializing in computer
                  hardware and electronics.
                </p>
              </Link>
              <Link>
                <img src={img11} alt="img" />
                <span>Cleveland Clinic</span>
                <p>
                  Cleveland Clinic is a nonprofit academic medical center that
                  provides clinical and hospital care.
                </p>
              </Link>
              <Link>
                <img src={img13} alt="img" />
                <br />
                <span>3M</span>
                <p>
                  3M is a diversified technology company known for its
                  manufacturing of various products, including adhesives,
                  abrasives, and personal protective equipment.
                </p>
              </Link>
            </div>
          </div>

          {/* top rated section */}
          <div className="Mobileappdev-top-rated-section">
            <p>Top-rated web development company</p>
            <div>
              <img src={Clutch} alt="abc" />
              <img src={ISO} alt="abc" />
              <img src={Pasha} alt="abc" />
              <img src={PSeb} alt="abc" />
            </div>
          </div>

          <div className="Mobileappdev-faq">
            <p>In case you're wondering ...</p>
            <div className="Mobileappdev-faq-wrapper">
              {FAQDATA.map((data) => (
                <div className="Mobileappdev-faq-container">
                  <div key={data.id} onClick={() => toggleFaq(data.id)}>
                    <p>{data.heading}</p>
                    {openId === data.id ? (
                      <LuMinus size={24} />
                    ) : (
                      <GoPlus size={24} />
                    )}
                  </div>
                  {openId === data.id && <span>{data.data}</span>}
                </div>
              ))}
            </div>
          </div>

          {/* contactus section */}
          <ContactUs />

          <Footer />
        </div>
      )}
    </>
  );
};

export default WebDevelopment;
