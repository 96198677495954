import React, { useEffect, useState } from "react";
import Nav from "../NavBar/Nav";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./MobileAppDevelopment.css";
import { Link } from "react-router-dom";
import { MdOutlineArrowForward } from "react-icons/md";
import { IoIosArrowRoundForward } from "react-icons/io";
import { GiCheckMark } from "react-icons/gi";

import icon1 from "../../App Assets/ios/Xcode-Logo-Vector.svg- 1.svg";
import icon2 from "../../App Assets/ios/image 1.svg";
import icon3 from "../../App Assets/ios/image 2.svg";
import icon4 from "../../App Assets/ios/image 3.svg";
import icon5 from "../../App Assets/andriod/image 4.svg";
import icon6 from "../../App Assets/andriod/image 6.svg";
import icon7 from "../../App Assets/andriod/image 7.svg";
import icon8 from "../../App Assets/andriod/image 8.svg";
import icon9 from "../../App Assets/cross/image 9.svg";
import icon10 from "../../App Assets/cross/image 10.svg";
import icon11 from "../../App Assets/cross/image 11.svg";
import icon12 from "../../App Assets/cross/image 12.svg";

import iosFintech from "../../App Assets/e-money 1.svg";
import iosREALeSTATE from "../../App Assets/home 1.svg";
import iosEcommerce from "../../App Assets/shopping-cart (1) 1.svg";
import iosHealthCare from "../../App Assets/first-aid-kit 1.svg";
import iosSupplyChain from "../../App Assets/stock 1.svg";
import iosLogistics from "../../App Assets/food-truck 1.svg";
import iosBankling from "../../App Assets/bank-transfer 1.svg";

import Clutch from "../../assets/mobile-dev-securities-and-exchange-commission-of-pakistan-logo-7828A88801-seeklogo 2.png";
import ISO from "../../assets/mobile-dev-iso-2-1-logo-png-transparent 2.png";
import Pasha from "../../assets/P@SHA_Logo 2.png";
import PSeb from "../../assets/pseb-logo-1 2.png";
import { GoPlus } from "react-icons/go";
import { LuMinus } from "react-icons/lu";

import contactUs from "../../assets/contactus-mobile-dev.png";
import appDevSericesPic from "../../assets/app-dev-services.png";
import pseudo from "../../assets/pseudo.png";
import TestimonialImg from "../../assets/Ellipse 8.png";

import img1 from "../../assets/Android App Development-01.png";
import img2 from "../../assets/Cross-Platform Mobile Apps-01.png";
import img3 from "../../assets/design thinking-01.png";
import img4 from "../../assets/iOS App Development-01.png";
import img5 from "../../assets/The Latest Tech-01.png";
import img6 from "../../assets/Top Quality-01.png";
import img7 from "../../App Assets/our-mobile-img1.png.png";
import img8 from "../../App Assets/our-mobile-img2.png.png";
import img9 from "../../App Assets/our-mobile-img3.png.png";
import img10 from "../../App Assets/image 13.svg";
import img11 from "../../App Assets/image 14.svg";
import img12 from "../../App Assets/image 15.svg";
import img13 from "../../App Assets/image 16.svg";

import Footer from "../Footer/Footer";
import Loader from "../Loader/Loader";
import ContactUs from "../ContactUs/ContactUs";

const FAQDATA = [
  {
    id: 1,
    heading: "What is the cost of mobile app development?",
    data: "Depending on the complexity of the app, mobile app development can cost about $24,000 for a simple app and around $137,000 for a more sophisticated app. Highly complex apps can cost $500,000 or more. The mobile app development company you partner with can also influence the cost of building your app.",
  },
  {
    id: 2,
    heading: "How can a mobile app benefit my business?",
    data: "Mobile apps are an effective way to build stronger relationships with your customers. The biggest benefit of a mobile app is increased brand awareness. You also get higher customer engagement and deeper insights into your customers’ behavior. This valuable data can be used to improve your ads, outreach efforts, and even future app versions.",
  },
  {
    id: 3,
    heading: "What kind of mobile apps do you develop?",
    data: "Legion builds both native and cross-platform mobile apps. We work with you to determine the best kind of app for your needs. Whether you want to build a mobile application from scratch, redesign an existing app, or modernize your mobile app infrastructure, you’ll get secure, stable, high-quality results from our mobile app development team. Contact us to learn more.",
  },
  {
    id: 4,
    heading: "How do you choose a mobile app development platform?",
    data: "When it comes to mobile app development, businesses have three options: Native, Hybrid, and Web. Native apps are specific to one operating system, providing the best performance and user experience. Hybrid apps combine elements of both Native and Web, offering cross-platform compatibility and cost-effectiveness. Web apps are accessed through browsers and are simple to use without installation.",
  },
  {
    id: 5,
    heading:
      "What programming language do you use to develop iOS/Android apps?",
    data: "Several programming languages can be used to build Android and iOS apps. Our mobile app development team uses the best language and technology combination for your needs.",
  },
];

const MobileAppDevelopment = () => {
  const [openId, setOpenId] = useState(null);
  const [activeMainTab, setActiveMainTab] = useState("IOS Apps");
  const [loading, setLoading] = useState(true);

  const [platForm, setPlatForm] = useState();
  const [selectDevice, setSelectDevice] = useState();
  const [stage, setStage] = useState();
  const [totalPlatoformPrice, setTotalPlatoformPrice] = useState();
  const [totalSelectDevicePrice, setTotalSelectDevicePrice] = useState();
  const [totalStagePrice, setTotalStagePrice] = useState();
  const [totalPrice, setTotalPrice] = useState(null);

  const [isScrolled, setIsScrolled] = useState(false);

  const toggleFaq = (id) => {
    setOpenId(openId === id ? null : id);
  };

  var settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    autoplaySpeed: 1000,
    slidesToScroll: 1,
    draggable: true,
  };

  const handleMainTabClick = (tab) => {
    setActiveMainTab(tab);
  };

  useEffect(() => {
    const imagesToLoad = [
      iosFintech,
      iosREALeSTATE,
      iosEcommerce,
      iosHealthCare,
      iosSupplyChain,
      iosLogistics,
      iosBankling,
      Clutch,
      ISO,
      Pasha,
      PSeb,
      contactUs,
      appDevSericesPic,
      pseudo,
      TestimonialImg,
    ];

    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = src;
      });
    };

    const loadImages = async () => {
      try {
        const promises = imagesToLoad.map((image) => loadImage(image));
        await Promise.all(promises);
        setLoading(false);
      } catch (error) {
        console.error("Error loading images:", error);
      }
    };

    loadImages();
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 230) {
      console.log("window.scrollY", window.scrollY);
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handlePlatformCheckboxChange = (value) => {
    setPlatForm(value);

    let platformPrice = 0;

    if (value === "IOS") {
      platformPrice = 35;
    } else if (value === "Android") {
      platformPrice = 30;
    } else if (value === "Cross-Platform") {
      platformPrice = 40;
    }

    setTotalPlatoformPrice(platformPrice);
  };

  const handleDeviceCheckboxChange = (value) => {
    setSelectDevice(value);

    let devicePrice = 0;

    if (value === "Mobile") {
      devicePrice = 4;
    } else if (value === "Tablet") {
      devicePrice = 5;
    } else if (value === "Watch") {
      devicePrice = 10;
    } else if (value === "Smart TV") {
      devicePrice = 12;
    }

    setTotalSelectDevicePrice(devicePrice);
  };

  const handleStageCheckboxChange = (value) => {
    setStage(value);

    let stagePrice = 0;

    if (value === "IOS") {
      stagePrice = 4;
    } else if (value === "Idea") {
      stagePrice = 5;
    } else if (value === "Prototype") {
      stagePrice = 10;
    } else if (value === "MVP") {
      stagePrice = 12;
    } else if (value === "Designed Solution") {
      stagePrice = 10;
    } else if (value === "Developed Application") {
      stagePrice = 12;
    }

    setTotalStagePrice(stagePrice);
  };

  const handleCalculateTotalPrice = () => {
    setTotalPrice(
      totalPlatoformPrice + totalSelectDevicePrice + totalStagePrice
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    AOS.init({ duration: 2000 });
    setLoading(false);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="Mobileappdev-container">
            <div
              className={`Mobileappdev-nav  ${
                isScrolled ? "Mobileappdev-nav-scrolled" : ""
              } `}
            >
              <Nav />
            </div>
            <div className="mobile-app-dev-wrapper">
              <div className="Bread-crum-mobileapp-dev">
                <Link to="/">Home</Link>
                <IoIosArrowRoundForward />
                <p>Services</p>
                <IoIosArrowRoundForward />
                <Link className="active-bread-crum-current-page">
                  Mobile App Development
                </Link>
              </div>

              <div className="mobile-app-hero-headings">
                <p>
                  App
                  <br />
                  Development
                </p>
                <span>
                  Save Time, Money, and Resources with Efficient Cross-Platform
                  App Development Solutions
                </span>
                <Link to={"/contact"}>
                  Contact Us Today
                  <MdOutlineArrowForward size={22} />
                </Link>
              </div>

              <div className="mobile-app-dev-hero-footer">
                <div>
                  <p>413+</p>
                  <span>Projects</span>
                </div>
                <div>
                  <p>327+</p>
                  <span>Mobile Tech Experts</span>
                </div>
                <div>
                  <p>4.9/5</p>
                  <span>On Clutch</span>
                </div>
              </div>
            </div>
          </div>

          {/* services section */}
          <div className="mobileappedev-think-legion">
            <p>
              For Innovative Mobile App Development, Choose Legions Soft for
              Your Fresh Solutions
            </p>
            <span>
              A well-crafted, intuitive UI makes it fast and easy for people to
              get what they need out of your product. The trick is making them
              love it.
            </span>

            <div className="mobileappedev-think-legion-section-1">
              <div>
                <div>
                  <img src={img3} alt="img" />
                </div>
                <p>Fresh Thinking</p>
                <span>
                  Experience Innovative Solutions: From comprehensive business
                  analysis to creative design and development, we provide a
                  fresh perspective to help you achieve your business goals.
                </span>
              </div>
              <div>
                <div>
                  <img src={img5} alt="img" />
                </div>
                <p>The Latest Tech</p>
                <span>
                  Stay Ahead with Cutting-Edge Technology: Benefit from the
                  latest mobile technology, supported by years of expertise in
                  delivering tailored mobile app development across diverse
                  industries.
                </span>
              </div>
              <div>
                <div>
                  <img src={img6} alt="img" />
                </div>
                <p>Top Quality</p>
                <span>
                  Quality You Can Trust: Whether you’re launching a new mobile
                  application or revamping an existing one, our team ensures you
                  receive a secure, stable, and responsive app tailored to your
                  needs.
                </span>
              </div>
              <div>
                <div>
                  <img src={img4} alt="img" />
                </div>
                <p>iOS App Development</p>
                <span>
                  Maximize Your iOS Potential: Unlock the full capabilities of
                  Apple devices with custom apps designed specifically for the
                  iOS platform, delivering seamless user experiences.
                </span>
              </div>
              <div>
                <div>
                  <img src={img1} alt="img" />
                </div>
                <p>Android App Development</p>
                <span>
                  Unlock the Power of Android: Develop an exclusive app that
                  takes full advantage of the features offered by Android
                  devices, ensuring optimal performance and usability.
                </span>
              </div>
              <div>
                <div>
                  <img src={img2} alt="img" />
                </div>
                <p>Cross-Platform Mobile Apps</p>
                <span>
                  Effortless Cross-Platform Experience: Get a stunning app that
                  performs flawlessly on both Android and iOS devices,
                  eliminating the need for managing separate versions.
                </span>
              </div>
            </div>

            <div className="mobileappedev-think-legion-section-2">
              <p>Our Mobile App Services</p>
              <span>
                Whether you need native or hybrid solutions, we create visually
                stunning apps tailored for any device or platform. Your
                satisfaction is our priority, and we won’t stop until you
                absolutely love your app!
              </span>
              <div className="mobileappedev-think-legion-section-2-part-1">
                <div>
                  <p>Consulting and Prototyping</p>
                  <span>
                    We provide expert consulting to assess the feasibility of
                    your mobile application idea and assist in creating a
                    prototype that brings your vision to life.
                  </span>
                  <br />
                  <br />
                  <span>
                    This allows potential investors and business partners to
                    evaluate the return on investment (ROI) before committing
                    resources to a full-scale development project.
                  </span>
                  <div>
                    <p>
                      <GiCheckMark />
                      Business Goals
                    </p>
                    <p>
                      <GiCheckMark />
                      User Requirements
                    </p>
                    <p>
                      <GiCheckMark />
                      Market Research
                    </p>
                  </div>
                </div>
                <img src={img7} alt="img" />
              </div>

              <div className="mobileappedev-think-legion-section-2-part-2">
                <img src={img8} alt="img" />
                <div>
                  <p>Integration</p>
                  <span>
                    We specialize in integrating new mobile applications to work
                    seamlessly with your existing business systems and website
                    tools.
                  </span>
                  <br />
                  <br />
                  <span>
                    Additionally, we can adapt and re-engineer your business
                    tools for mobile access, enabling your employees to retrieve
                    and submit information in real-time, anytime and anywhere.
                  </span>
                  <div>
                    <p>
                      <GiCheckMark />
                      Information Architecture
                    </p>
                    <p>
                      <GiCheckMark />
                      Wireframes
                    </p>
                  </div>
                </div>
              </div>

              <div className="mobileappedev-think-legion-section-2-part-1">
                <div>
                  <p>Native App Development</p>
                  <span>
                    Our expert developers specialize in designing and building
                    high-quality native apps for both iOS and Android platforms,
                    ensuring they meet rigorous security and business
                    requirements.
                  </span>
                  <br />
                  <br />
                  <span>
                    Native apps provide your customers with a feature-rich
                    mobile experience that integrates seamlessly with their
                    device's operating system.
                  </span>
                  <div>
                    <p>
                      <GiCheckMark />
                      Visual Design
                    </p>
                    <p>
                      <GiCheckMark />
                      Style Guide & Design Systems
                    </p>
                    <p>
                      <GiCheckMark />
                      Prototype and Test
                    </p>
                  </div>
                </div>
                <img src={img9} alt="img" />
              </div>
            </div>
          </div>

          {/* tech section */}
          <div className="Mobileappdev-tech-section">
            <p>Technologies & Tools We Use</p>
            <div className="Mobileappdev-tech-section-container">
              <div className="Mobileappdev-tech-tabs">
                <button
                  className={
                    activeMainTab === "IOS Apps"
                      ? "Mobileappdev-tech-tabs-button-active"
                      : ""
                  }
                  onClick={() => handleMainTabClick("IOS Apps")}
                >
                  IOS Apps
                </button>
                <button
                  className={
                    activeMainTab === "Android app"
                      ? "Mobileappdev-tech-tabs-button-active"
                      : ""
                  }
                  onClick={() => handleMainTabClick("Android app")}
                >
                  Android apps
                </button>
                <button
                  className={
                    activeMainTab === "Cross-Platforms Engineering"
                      ? "Mobileappdev-tech-tabs-button-active"
                      : ""
                  }
                  onClick={() =>
                    handleMainTabClick("Cross-Platforms Engineering")
                  }
                >
                  Cross-Platforms Engineering
                </button>
              </div>
              {activeMainTab === "IOS Apps" && (
                <div className="Mobileappdev-tech-section-tabs-body">
                  <div>
                    <span>
                      <img src={icon1} alt="abc" />
                    </span>
                    <p>Xcode</p>
                  </div>
                  <div>
                    <span>
                      <img src={icon2} alt="abc" />
                    </span>
                    <p>Swift</p>
                  </div>
                  <div>
                    <span>
                      <img src={icon3} alt="abc" />
                    </span>
                    <p>Objective-C</p>
                  </div>
                  <div>
                    <span>
                      <img src={icon4} alt="abc" />
                    </span>
                    <p>Cocoa Touch</p>
                  </div>
                </div>
              )}
              {activeMainTab === "Android app" && (
                <div className="Mobileappdev-tech-section-tabs-body">
                  <div>
                    <span>
                      <img src={icon5} alt="abc" />
                    </span>
                    <p>Kotlin</p>
                  </div>
                  <div>
                    <span>
                      <img src={icon6} alt="abc" />
                    </span>
                    <p>Android Studio</p>
                  </div>
                  <div>
                    <span>
                      <img src={icon7} alt="abc" />
                    </span>
                    <p>Jetpack</p>
                  </div>
                  <div>
                    <span>
                      <img src={icon8} alt="abc" />
                    </span>
                    <p>Java</p>
                  </div>
                </div>
              )}
              {activeMainTab === "Cross-Platforms Engineering" && (
                <div className="Mobileappdev-tech-section-tabs-body">
                  <div>
                    <span>
                      <img src={icon9} alt="abc" />
                    </span>
                    <p>Flutter</p>
                  </div>
                  <div>
                    <span>
                      <img src={icon10} alt="abc" />
                    </span>
                    <p>React Native</p>
                  </div>
                  <div>
                    <span>
                      <img src={icon11} alt="abc" />
                    </span>
                    <p>Xamarin</p>
                  </div>
                  <div>
                    <span>
                      <img src={icon12} alt="abc" />
                    </span>
                    <p>Ionic</p>
                  </div>
                </div>
              )}
            </div>

            <Link
              className="Mobileappdev-tech-section-button"
              to={"/talk-to-us"}
            >
              Request Services
              <MdOutlineArrowForward size={22} />
            </Link>
          </div>

          {/* ios dev solution section */}
          <div className="Mobileappdev-ios-dev-section">
            <p>
              Our iOS Development Solutions
              <br />
              For Different
              <span> Industries</span>
            </p>
            <div className="Mobileappdev-ios-dev-section-items">
              <div>
                <img src={iosFintech} alt="abc" />
                <p>Fintech</p>
              </div>
              <div>
                <img src={iosREALeSTATE} alt="abc" />
                <p>Real Estate</p>
              </div>
              <div>
                <img src={iosEcommerce} alt="abc" />
                <p>E-Commerce</p>
              </div>
              <div>
                <img src={iosHealthCare} alt="abc" />
                <p>Healthcare</p>
              </div>
              <div>
                <img src={iosSupplyChain} alt="abc" />
                <p>Supply Chain</p>
              </div>
              <div>
                <img src={iosLogistics} alt="abc" />
                <p>Logistics</p>
              </div>
              <div>
                <img src={iosBankling} alt="abc" />
                <p>Banking</p>
              </div>
            </div>
          </div>

          {/* Request the price */}
          <div className="Mobileappdev-request-for-price-section">
            <p>
              Request the price of{" "}
              <span>
                Mobile App
                <br />
                Development
              </span>{" "}
              Services
            </p>

            <div className="Mobileappdev-request-for-price-section-container">
              <div className="Mobileappdev-request-for-price-section-wrapper">
                <p>01. Platform</p>
                <div>
                  <div>
                    <input
                      type="checkbox"
                      checked={platForm === "IOS"}
                      onChange={() => handlePlatformCheckboxChange("IOS")}
                    />
                    <p>IOS</p>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={platForm === "Android"}
                      onChange={() => handlePlatformCheckboxChange("Android")}
                    />
                    <p>Android</p>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={platForm === "Cross-Platform"}
                      onChange={() =>
                        handlePlatformCheckboxChange("Cross-Platform")
                      }
                    />
                    <p>Cross-Platform</p>
                  </div>
                </div>
              </div>
              <div className="Mobileappdev-request-for-price-section-wrapper">
                <p>02. Device</p>
                <div>
                  <div>
                    <input
                      type="checkbox"
                      checked={selectDevice === "Mobile"}
                      onChange={() => handleDeviceCheckboxChange("Mobile")}
                    />
                    <p>Mobile</p>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={selectDevice === "Tablet"}
                      onChange={() => handleDeviceCheckboxChange("Tablet")}
                    />
                    <p>Tablet</p>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={selectDevice === "Watch"}
                      onChange={() => handleDeviceCheckboxChange("Watch")}
                    />
                    <p>Watch</p>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={selectDevice === "Smart TV"}
                      onChange={() => handleDeviceCheckboxChange("Smart TV")}
                    />
                    <p>Smart TV</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="Mobileappdev-request-for-price-section-container">
              <div className="Mobileappdev-request-for-price-section-wrapper Mobileappdev-request-for-price-section-wrapper-mod3">
                <p>03. Stage</p>
                <div>
                  <div>
                    <input
                      type="checkbox"
                      checked={stage === "IOS"}
                      onChange={() => handleStageCheckboxChange("IOS")}
                    />
                    <p>IOS</p>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={stage === "Idea"}
                      onChange={() => handleStageCheckboxChange("Idea")}
                    />
                    <p>Idea</p>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={stage === "Prototype"}
                      onChange={() => handleStageCheckboxChange("Prototype")}
                    />
                    <p>Prototype / Specification</p>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={stage === "MVP"}
                      onChange={() => handleStageCheckboxChange("MVP")}
                    />
                    <p>MVP</p>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={stage === "Designed Solution"}
                      onChange={() =>
                        handleStageCheckboxChange("Designed Solution")
                      }
                    />
                    <p>Designed Solution</p>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={stage === "Developed Application"}
                      onChange={() =>
                        handleStageCheckboxChange("Developed Application")
                      }
                    />
                    <p>Developed Application</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="Mobileappdev-request-for-price-section-button">
              <p>Select the checkbox to get price</p>
              {stage && selectDevice && platForm ? (
                <button onClick={handleCalculateTotalPrice}>Calculate</button>
              ) : (
                ""
              )}
              {totalPrice !== null ? <p>{totalPrice}$ per hour</p> : ""}
              {/* <MdOutlineArrowForward size={22}/> */}
            </div>
          </div>

          {/* What It Looks Like */}
          <div className="Mobileappdev-looks-like">
            <p>How Visual Experience will be?</p>
            <div className="Mobileappdev-looks-like-section-1">
              <div>
                <p>Stunning User Interfaces</p>
              </div>
              <div>
                <p>Seamless Navigation</p>
              </div>
              <div>
                <p>Responsive Design </p>
              </div>
              <div>
                <p>High-Performance Functionality</p>
              </div>
            </div>
            <p>Our Process</p>
            <div className="Mobileappdev-looks-like-section-2">
              <Slider {...settings}>
                <div className="Mobileappdev-looks-like-section-2-part-1">
                  <p>1</p>
                  <p>Initial Consultation</p>
                  <span>
                    We begin with a detailed consultation to understand your
                    goals and requirements.
                  </span>
                </div>
                <div className="Mobileappdev-looks-like-section-2-part-1">
                  <p>2</p>
                  <p>Research and Planning</p>
                  <span>Our team conducts thorough research and planning.</span>
                </div>
                <div className="Mobileappdev-looks-like-section-2-part-1">
                  <p>3</p>
                  <p>Design and Prototyping</p>
                  <span>
                    We create wireframes and prototypes to visualize the app’s.
                  </span>
                </div>
                <div className="Mobileappdev-looks-like-section-2-part-1">
                  <p>4</p>
                  <p>Development</p>
                  <span>
                    Our skilled developers build the app using the latest
                    technologies, ensuring high quality and performance.
                  </span>
                </div>
                <div className="Mobileappdev-looks-like-section-2-part-1">
                  <p>5</p>
                  <p>Testing and Quality Assurance</p>
                  <span>
                    We rigorously test the app to identify and fix any issues,
                    ensuring it meets our quality standards.
                  </span>
                </div>
                <div className="Mobileappdev-looks-like-section-2-part-1">
                  <p>6</p>
                  <p>Launch and Support</p>
                  <span>
                    After a successful launch, we provide ongoing support and
                    updates.
                  </span>
                </div>
              </Slider>
            </div>
            <Link>Contact Us</Link>
          </div>

          <div className="Mobileappdev-portfolio">
            <p>Our work speaks for itself ...</p>
            <span>
              Our design-first approach has helped transform innovative
              businesses like these.
            </span>
            <div>
              <Link>
                <img src={img10} alt="img" />
                <span>TaskMaster</span>
                <p>
                  A powerful task management tool designed to help users
                  organize, prioritize, and collaborate on their daily
                  activities effortlessly.
                </p>
              </Link>
              <Link>
                <img src={img11} alt="img" />
                <span>ShopSmart</span>
                <p>
                  An intuitive e-commerce platform that enables users to browse,
                  purchase, and manage products seamlessly, providing a
                  delightful shopping experience.
                </p>
              </Link>
              <Link>
                <img src={img12} alt="img" />
                <span>FitTrack</span>
                <p>
                  A comprehensive health and fitness app that empowers users to
                  track workouts, set goals, and monitor nutrition for a
                  healthier lifestyle.
                </p>
              </Link>
              <Link>
                <img src={img13} alt="img" />
                <span>BudgetWise</span>
                <p>
                  A user-friendly finance management app that simplifies expense
                  tracking, budgeting, and savings, helping users take control
                  of their finances.
                </p>
              </Link>
            </div>
          </div>

          {/* top rated section */}
          <div className="Mobileappdev-top-rated-section">
            <p>Top-rated mobile development company</p>
            <div>
              <img src={Clutch} alt="abc" />
              <img src={ISO} alt="abc" />
              <img src={Pasha} alt="abc" />
              <img src={PSeb} alt="abc" />
            </div>
          </div>

          <div className="Mobileappdev-faq">
            <p>In case you're wondering ...</p>
            <div className="Mobileappdev-faq-wrapper">
              {FAQDATA.map((data) => (
                <div className="Mobileappdev-faq-container">
                  <div key={data.id} onClick={() => toggleFaq(data.id)}>
                    <p>{data.heading}</p>
                    {openId === data.id ? (
                      <LuMinus size={24} />
                    ) : (
                      <GoPlus size={24} />
                    )}
                  </div>
                  {openId === data.id && <span>{data.data}</span>}
                </div>
              ))}
            </div>
          </div>

          {/* contactus section */}
          <ContactUs />

          <Footer />
        </div>
      )}
    </>
  );
};

export default MobileAppDevelopment;
